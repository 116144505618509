<template>
    <div class="flex items-center justify-center">
        <el-dropdown trigger="click" placement="bottom">
            <div class="flex items-center">
                <activix-tooltip :content="invalidTooltip" v-if="lead.isInvalid">
                    <icon class="link-orange" :class="iconClass" :name="invalidIcon" />
                </activix-tooltip>
                <activix-tooltip :content="duplicateTooltip" v-else-if="lead.isDuplicate">
                    <icon class="link-grey" :class="iconClass" :name="duplicateIcon" />
                </activix-tooltip>
                <activix-tooltip :content="lostTooltip" v-else-if="lead.isLost">
                    <icon class="link-red" :class="iconClass" :name="lostIcon" />
                </activix-tooltip>
                <activix-tooltip :content="$t('result.active')" v-else>
                    <icon class="link-green" :class="iconClass" :name="activeIcon" />
                </activix-tooltip>
            </div>

            <el-dropdown-menu class="p-0" slot="dropdown">
                <el-dropdown-item :disabled="!authorized" @click.native="toggleStatus(null)">
                    <icon class="link-green mr-1" :name="activeIcon" />
                    {{ $t('result.active') }}
                </el-dropdown-item>
                <activix-tooltip :content="modified ? $t('clientCard.alertInvalidStatus') : ''" placement="right">
                    <div>
                        <el-dropdown-item :disabled="!authorized || modified" @click.native="toggleStatus('invalid')">
                            <icon class="link-orange mr-1" :name="invalidIcon" />
                            {{ $t('result.invalid') }}
                        </el-dropdown-item>
                    </div>
                </activix-tooltip>
                <el-dropdown-item :disabled="!authorized" @click.native="toggleStatus('duplicate')">
                    <icon class="link-grey mr-1" :name="duplicateIcon" />
                    {{ $t('result.duplicate') }}
                </el-dropdown-item>
                <el-dropdown-item :disabled="!authorized" @click.native="toggleStatus('lost')">
                    <icon class="link-red mr-1" :name="lostIcon" />
                    {{ $t('result.lost') }}
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>

        <modal-status
            :lead="lead"
            :opened.sync="statusModalOpened"
            :status="status"
            @approved="openDeleteFutureTaskModal"
        />
        <activix-confirm-modal
            approve-text="OK"
            :content="$t('clientCard.alertInvalidStatus')"
            :show-deny="false"
            :title="$t('error.warning')"
            :opened.sync="invalidDisabledModalOpened"
        />
        <activix-confirm-modal
            :approve-text="$t('dashboards.removeIt')"
            :opened.sync="removeStatusModalOpened"
            :title="$t('general.areYouSure')"
            @approve="triggerToggleStatus"
        />

        <activix-confirm-modal
            type="info"
            :content="$t('dashboards.deleteUpcomingTask.description')"
            :approve-text="$t('dashboards.deleteUpcomingTask.delete')"
            :deny-text="$t('dashboards.deleteUpcomingTask.keep')"
            :opened.sync="deleteFutureTaskModalOpened"
            @approve="deleteUpcomingTask"
        />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'pinia';

    // Components
    import ModalStatus from '../../modals/ModalStatus.vue';

    // Value Objects
    import ActivixDate from '../../../value-objects/ActivixDate.js';

    // Entities
    import User from '../../../entities/User.js';
    import LeadStatus from '../../../entities/LeadStatus.js';
    import LeadType from '../../../entities/LeadType.js';
    import Lead from '../../../entities/Lead.js';

    // Pinia
    import { useContextStore } from '../../../store/modules/context/store.js';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        components: {
            ModalStatus,
        },

        props: {
            lead: {
                type: Lead,
                default: new Lead(),
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            iconClass: {
                type: String,
                default: 'text-2xl bg-white rounded-full',
            },
        },

        data() {
            return {
                invalidDisabledModalOpened: false,
                removeStatusModalOpened: false,
                statusModalOpened: false,
                deleteFutureTaskModalOpened: false,
                status: 'lost',
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            statusUpdatedAt() {
                return new ActivixDate(this.lead.status_updated_at);
            },

            activeIcon() {
                return LeadStatus.getIcon(null);
            },

            duplicateIcon() {
                return LeadStatus.getIcon(LeadStatus.DUPLICATE);
            },

            invalidIcon() {
                return LeadStatus.getIcon(LeadStatus.INVALID);
            },

            lostIcon() {
                return LeadStatus.getIcon(LeadStatus.LOST);
            },

            lostTooltip() {
                return this.generateStatusTooltip('lost');
            },

            invalidTooltip() {
                return this.generateStatusTooltip('invalid');
            },

            duplicateTooltip() {
                return this.generateStatusTooltip('duplicate');
            },

            authorized() {
                if (this.disabled) {
                    return false;
                }

                if (!this.authUser.isAuthorizedToActionOnLead(this.lead)) {
                    return false;
                }

                if (!this.lead.user_id && this.authUser.isAdvisor()) {
                    return false;
                }

                if (this.authUser.isBdc() && !this.lead.bdc_user_id && !this.authUser.isSuperBdc()) {
                    return false;
                }

                return true;
            },

            modified() {
                const hasDate =
                    !!this.lead.appointment_date ||
                    !!this.lead.presented_date ||
                    !!this.lead.sale_date ||
                    !!this.lead.delivered_date ||
                    !!this.lead.road_test_date ||
                    !!this.lead.be_back_date ||
                    !!this.lead.take_over_date;

                return hasDate && !this.isSertiWalkInLead && this.lead.status != 'invalid';
            },

            isSertiWalkInLead() {
                return this.lead.created_method == 'serti' && this.lead.lead_type_id == LeadType.WALK_IN;
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            generateStatusTooltip(type) {
                let returnValue = '';

                returnValue = this.$t(`result.${type}`);

                if (this.lead.status == type) {
                    returnValue = `<strong>${this.$t(`result.${type}`)}</strong>`;

                    if (type == 'lost' || type == 'invalid') {
                        if (this.lead.lost_reason) {
                            returnValue += `<br>${this.lead.lost_reason}`;
                        }

                        if (type == 'lost' && this.lead.lost_source) {
                            returnValue += `<br>${this.$t('result.canceledByUser')} : ${this.$t(
                                `result.${this.lead.lost_source}`,
                            )}`;
                        }
                    }

                    const userLabel = 'Activix CRM';
                    let userName = '';

                    if (this.lead.status_updated_by_id) {
                        let user = this.contextAccount.users.find(user => {
                            return (
                                user.id == this.lead.status_updated_by_id ||
                                user.parent_user_id == this.lead.status_updated_by_id
                            );
                        });

                        if (user) {
                            user = new User(user);
                            userName = user.shortName;
                        }
                    }

                    returnValue += `<br>${this.$t('general.by')} : ${userName || userLabel}`;

                    if (this.lead.status_updated_at) {
                        returnValue += `<br>${this.statusUpdatedAt.toHumanShort()}`;
                    }
                }

                return returnValue;
            },

            triggerToggleStatus(status = null) {
                this.updateLeadAction(this.lead.id, {
                    status,
                    lost_reason: null,
                    lost_source: null,
                    status_updated_at: new ActivixDate('now').toString(),
                });
            },

            deleteUpcomingTask() {
                this.updateLeadAction(this.lead.id, {}, { deleteUpcomingTask: true });
            },

            toggleStatus(status) {
                if (!this.authorized) {
                    return;
                }

                if (this.modified && status == LeadStatus.INVALID) {
                    this.invalidDisabledModalOpened = true;
                    return;
                }

                if (this.lead.status == status) {
                    this.removeStatusModalOpened = true;
                    return;
                }

                if (status == LeadStatus.LOST || status == LeadStatus.INVALID) {
                    this.status = status;
                    this.statusModalOpened = true;

                    return;
                }

                if (status == LeadStatus.DUPLICATE) {
                    this.$modal.show('duplicateInfo');
                }

                this.triggerToggleStatus(status);
            },

            openDeleteFutureTaskModal() {
                const filteredTasks = this.lead.task_events.filter(task => {
                    const startDate = new ActivixDate(task.start_at);
                    return startDate.isFuture();
                });

                if (filteredTasks.length) {
                    this.deleteFutureTaskModalOpened = true;
                }
            },
        },

        created() {
            this.$eventBus.$on('open-delete-future-task-modal', this.openDeleteFutureTaskModal);
        },

        beforeDestroy() {
            this.$eventBus.$off('open-delete-future-task-modal', this.openDeleteFutureTaskModal);
        },
    };
</script>
