var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box",class:{ loading: _vm.loading }},[_c('div',{staticClass:"box-header | h-auto p-6 flex justify-between items-center"},[_c('h4',{staticClass:"box-title | flex items-baseline"},[_vm._v(" "+_vm._s(_vm.$t('search.results'))+" "),_c('activix-reload',{attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.$emit('reload')}}})],1),_c('div',{staticClass:"flex ml-4"},[_c('activix-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$eventBus.$emit('open-lead-create', {}, _vm.$route.query.value)}}},[_c('icon',{staticClass:"lg:mr-2 lg:text-sm",attrs:{"name":"bold/add"}}),(!_vm.lgLayout)?[_vm._v(" "+_vm._s(_vm.$t('general.add'))+" ")]:_vm._e()],2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tableState.searchToken),expression:"tableState.searchToken"}],staticClass:"form-control | flex-1 ml-2",attrs:{"type":"text","placeholder":_vm.$t('search.search')},domProps:{"value":(_vm.tableState.searchToken)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tableState, "searchToken", $event.target.value)}}})],1)]),_c('div',{staticClass:"box-body | p-0"},[_c('el-table',{ref:"leadTable",staticClass:"w-full border-t-2 border-b",attrs:{"border":true,"data":_vm.paginatedLeads,"stripe":true},on:{"sort-change":_vm.updateTableSorting}},[_c('el-table-column',{attrs:{"prop":"fullName","header-align":"center","label":_vm.$t('clientCard.name'),"sortable":true,"min-width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var lead = ref.row;
return [_c('client-name',{attrs:{"lead":lead,"light":true}})]}}])}),_c('el-table-column',{attrs:{"prop":"status","header-align":"center","align":"center","label":_vm.$t('clientCard.status'),"sortable":true,"min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var lead = ref.row;
return [_c('lead-status',{attrs:{"lead":lead,"disabled":true}})]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"leadPhones","width":"115","label":_vm.$t('clientCard.phone'),"sortable":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var lead = ref.row;
return [(!lead.phones.length)?[_vm._v(" - ")]:_vm._e(),_vm._l((lead.getUniquePhones()),function(phone){return _c('div',{key:phone.id},[_vm._v(" "+_vm._s(_vm.displayPhone(phone.number))+" ")])})]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"leadEmails","min-width":"150","label":_vm.$t('clientCard.email'),"sortable":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var lead = ref.row;
return [(!lead.emails.length)?[_vm._v(" - ")]:_vm._e(),_vm._l((lead.emails),function(email){return _c('div',{key:email.id},[_vm._v(" "+_vm._s(_vm.displayEmail(email.address))+" ")])})]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"advisorName","label":_vm.$t('general.advisor'),"sortable":true}}),_c('el-table-column',{attrs:{"align":"center","prop":"divisionName","label":_vm.$t('general.division'),"sortable":true}}),_c('el-table-column',{attrs:{"align":"center","prop":"mainDate","width":"95","label":_vm.$t('clientCard.date'),"sortable":true,"formatter":_vm.handleTableDateTimeFormat}}),_c('el-table-column',{attrs:{"align":"center","prop":"updated_at","width":"95","label":_vm.$t('clientCard.updatedAt'),"sortable":true,"formatter":_vm.handleTableDateTimeFormat}}),_c('el-table-column',{attrs:{"align":"center","prop":"wantedVehicle","label":_vm.$t('clientCard.vehicle'),"sortable":true}}),_c('el-table-column',{attrs:{"align":"center","prop":"wantedStock","label":_vm.$t('general.stock'),"sortable":true}}),_c('el-table-column',{attrs:{"align":"center","prop":"wantedVin","label":_vm.$t('clientCard.vehicles.vin'),"sortable":true}}),_c('el-table-column',{attrs:{"align":"center","prop":"typeName","label":_vm.$t('accounts.edit.accountType'),"sortable":true,"width":"120"}}),_c('el-table-column',{attrs:{"align":"center","prop":"accountName","label":_vm.$t('general.account'),"sortable":true}}),_c('el-table-column',{attrs:{"align":"center","prop":"sale_date","label":_vm.$t('clientCard.sold'),"sortable":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var lead = ref.row;
return [_vm._v(" "+_vm._s(lead.sale_date ? _vm.$t('general.yes') : _vm.$t('general.no'))+" ")]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"delivered_date","label":_vm.$t('date.delivered'),"sortable":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var lead = ref.row;
return [_vm._v(" "+_vm._s(lead.delivered_date ? _vm.$t('general.yes') : _vm.$t('general.no'))+" ")]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"wantedOffer","label":_vm.$t('general.offer'),"sortable":true}}),_c('el-table-column',{attrs:{"align":"center","prop":"wantedOrder","label":_vm.$t('accounts.edit.orderNumber'),"sortable":true,"min-width":"110"}}),_c('el-table-column',{attrs:{"align":"center","prop":"sourceName","label":_vm.$t('clientCard.origin'),"sortable":true}}),_c('el-table-column',{attrs:{"align":"center","width":"50","sortable":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var lead = ref.row;
return [(_vm.canDeleteLead)?_c('a',{staticClass:"link-grey | hover:text-red-500",on:{"click":function($event){return _vm.onDelete(lead.id)}}},[_c('activix-tooltip',{attrs:{"content":_vm.$t('general.delete')}},[_c('icon',{attrs:{"name":_vm.$icons.trash}})],1)],1):_vm._e()]}}])})],1),_c('el-pagination',{class:_vm.mdLayout ? 'p-4' : 'p-6',attrs:{"layout":_vm.paginationLayout,"background":true,"current-page":_vm.tableState.currentPage,"page-size":_vm.tableState.perPage,"page-sizes":_vm.tableState.pageOptions,"pager-count":5,"total":_vm.filteredSortedLeads.length},on:{"update:currentPage":function($event){return _vm.$set(_vm.tableState, "currentPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.tableState, "currentPage", $event)},"update:pageSize":function($event){return _vm.$set(_vm.tableState, "perPage", $event)},"update:page-size":function($event){return _vm.$set(_vm.tableState, "perPage", $event)},"size-change":_vm.updateTablePerPage}})],1),_c('activix-confirm-modal',{attrs:{"type":"warning","content":_vm.$tc('client.deleteLeads.confirmation', 1),"opened":_vm.deleteModal.opened},on:{"update:opened":function($event){return _vm.$set(_vm.deleteModal, "opened", $event)},"approve":_vm.deleteLead,"closed":function($event){_vm.deleteModal.leadId = null}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }