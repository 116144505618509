<template>
    <div class="box" :class="{ loading: loading }">
        <div class="box-header | h-auto p-6 flex justify-between items-center">
            <h4 class="box-title | flex items-baseline">
                {{ $t('search.results') }}
                <activix-reload :loading="loading" @click="$emit('reload')" />
            </h4>
            <div class="flex ml-4">
                <activix-button type="primary" @click="$eventBus.$emit('open-lead-create', {}, $route.query.value)">
                    <icon name="bold/add" class="lg:mr-2 lg:text-sm" />
                    <template v-if="!lgLayout">
                        {{ $t('general.add') }}
                    </template>
                </activix-button>
                <input
                    class="form-control | flex-1 ml-2"
                    type="text"
                    :placeholder="$t('search.search')"
                    v-model="tableState.searchToken"
                />
            </div>
        </div>
        <div class="box-body | p-0">
            <el-table
                class="w-full border-t-2 border-b"
                :border="true"
                :data="paginatedLeads"
                :stripe="true"
                ref="leadTable"
                @sort-change="updateTableSorting"
            >
                <el-table-column
                    prop="fullName"
                    header-align="center"
                    :label="$t('clientCard.name')"
                    :sortable="true"
                    min-width="150"
                >
                    <template slot-scope="{ row: lead }">
                        <client-name :lead="lead" :light="true" />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="status"
                    header-align="center"
                    align="center"
                    :label="$t('clientCard.status')"
                    :sortable="true"
                    min-width="100"
                >
                    <template slot-scope="{ row: lead }">
                        <lead-status :lead="lead" :disabled="true" />
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="leadPhones"
                    width="115"
                    :label="$t('clientCard.phone')"
                    :sortable="true"
                >
                    <template slot-scope="{ row: lead }">
                        <template v-if="!lead.phones.length">
                            -
                        </template>
                        <div :key="phone.id" v-for="phone in lead.getUniquePhones()">
                            {{ displayPhone(phone.number) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="leadEmails"
                    min-width="150"
                    :label="$t('clientCard.email')"
                    :sortable="true"
                >
                    <template slot-scope="{ row: lead }">
                        <template v-if="!lead.emails.length">
                            -
                        </template>
                        <div :key="email.id" v-for="email in lead.emails">
                            {{ displayEmail(email.address) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="advisorName"
                    :label="$t('general.advisor')"
                    :sortable="true"
                />
                <el-table-column
                    align="center"
                    prop="divisionName"
                    :label="$t('general.division')"
                    :sortable="true"
                />
                <el-table-column
                    align="center"
                    prop="mainDate"
                    width="95"
                    :label="$t('clientCard.date')"
                    :sortable="true"
                    :formatter="handleTableDateTimeFormat"
                />
                <el-table-column
                    align="center"
                    prop="updated_at"
                    width="95"
                    :label="$t('clientCard.updatedAt')"
                    :sortable="true"
                    :formatter="handleTableDateTimeFormat"
                />
                <el-table-column
                    align="center"
                    prop="wantedVehicle"
                    :label="$t('clientCard.vehicle')"
                    :sortable="true"
                />
                <el-table-column
                    align="center"
                    prop="wantedStock"
                    :label="$t('general.stock')"
                    :sortable="true"
                />
                <el-table-column
                    align="center"
                    prop="wantedVin"
                    :label="$t('clientCard.vehicles.vin')"
                    :sortable="true"
                />
                <el-table-column
                    align="center"
                    prop="typeName"
                    :label="$t('accounts.edit.accountType')"
                    :sortable="true"
                    width="120"
                />
                <el-table-column
                    align="center"
                    prop="accountName"
                    :label="$t('general.account')"
                    :sortable="true"
                />
                <el-table-column
                    align="center"
                    prop="sale_date"
                    :label="$t('clientCard.sold')"
                    :sortable="true"
                >
                    <template slot-scope="{ row: lead }">
                        {{ lead.sale_date ? $t('general.yes') : $t('general.no') }}
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="delivered_date"
                    :label="$t('date.delivered')"
                    :sortable="true"
                >
                    <template slot-scope="{ row: lead }">
                        {{ lead.delivered_date ? $t('general.yes') : $t('general.no') }}
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="wantedOffer"
                    :label="$t('general.offer')"
                    :sortable="true"
                />
                <el-table-column
                    align="center"
                    prop="wantedOrder"
                    :label="$t('accounts.edit.orderNumber')"
                    :sortable="true"
                    min-width="110"
                />
                <el-table-column
                    align="center"
                    prop="sourceName"
                    :label="$t('clientCard.origin')"
                    :sortable="true"
                />
                <el-table-column align="center" width="50" :sortable="false">
                    <template slot-scope="{ row: lead }">
                        <a class="link-grey | hover:text-red-500" @click="onDelete(lead.id)" v-if="canDeleteLead">
                            <activix-tooltip :content="$t('general.delete')">
                                <icon :name="$icons.trash" />
                            </activix-tooltip>
                        </a>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :class="mdLayout ? 'p-4' : 'p-6'"
                :layout="paginationLayout"
                :background="true"
                :current-page.sync="tableState.currentPage"
                :page-size.sync="tableState.perPage"
                :page-sizes="tableState.pageOptions"
                :pager-count="5"
                :total="filteredSortedLeads.length"
                @size-change="updateTablePerPage"
            />
        </div>

        <activix-confirm-modal
            type="warning"
            :content="$tc('client.deleteLeads.confirmation', 1)"
            :opened.sync="deleteModal.opened"
            @approve="deleteLead"
            @closed="deleteModal.leadId = null"
        />
    </div>
</template>

<script>
    import { orderBy, camelCase } from 'lodash-es';

    import { mapState } from 'pinia';
    import { formater } from '../../globals/LeadFormatter.js';
    import DataTableMixin from '../../mixins/DataTable.js';
    import LeadType from '../../entities/LeadType.js';
    import Lead from '../../entities/Lead.js';
    import LeadVehicle from '../../entities/LeadVehicle.js';

    import ClientName from '../dashboards/columns/ClientName.vue';
    import LeadStatus from '../dashboards/columns/LeadStatus.vue';
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        components: {
            ClientName,
            LeadStatus,
        },

        mixins: [DataTableMixin],

        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            results: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                deleteModal: {
                    opened: false,
                    leadId: null,
                },
                tableState: {
                    searchToken: '',
                    currentPage: 1,
                    perPage: 10,
                    sorting: {
                        column: 'created_at',
                        order: 'desc',
                    },
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            canDeleteLead() {
                return this.authUser.isAdmin() || this.authUser.custom_permissions.delete_lead;
            },

            formatedLeads() {
                return this.results.map(tmpLead => {
                    const lead = new Lead(tmpLead);
                    const wantedVehicle = new LeadVehicle(lead.getSoldOrFirstWantedVehicle());
                    const source = formater.formatSource(lead);

                    lead.advisorName = this.getFullName(lead.user, false) || '-';
                    lead.divisionName = lead.division ? this.$t(`divisions.${lead.division}`) : '-';
                    lead.wantedVehicle = wantedVehicle.toString() || '-';
                    lead.wantedStock = wantedVehicle.stock || '-';
                    lead.wantedVin = wantedVehicle.vin || '-';
                    lead.wantedOffer = wantedVehicle.offer_number || '-';
                    lead.wantedOrder = wantedVehicle.order_number || '-';
                    lead.typeName = this.$t(`leadTypes.${camelCase(lead.type)}`);
                    lead.accountName = formater.formatAccountName(lead);
                    lead.sourceName = source == 'none' ? '-' : source;
                    lead.leadPhones = formater.formatPhone(lead) || '-';
                    lead.leadEmails = formater.formatEmail(lead) || '-';

                    switch (lead.lead_type_id) {
                        case LeadType.EMAIL:
                        case LeadType.LOYALTY:
                        case LeadType.RENEWAL:
                        case LeadType.WEB_ORDER:
                            lead.mainDate = lead.created_at;
                            break;

                        case LeadType.PHONE:
                            lead.mainDate = lead.call_date;
                            break;

                        case LeadType.WALK_IN:
                            lead.mainDate = lead.presented_date;
                            break;

                        default:
                            lead.mainDate = lead.created_at;
                            break;
                    }

                    if (this.$route.query.type == 'recent') {
                        lead.mainDate = lead.created_at;
                    }

                    return lead;
                });
            },

            filteredSortedLeads() {
                const filteredData = this.searchData(this.tableState.searchToken, this.formatedLeads);
                const sortedData = orderBy(
                    filteredData,
                    [this.tableState.sorting.column],
                    [this.tableState.sorting.order],
                );

                return sortedData;
            },

            paginatedLeads() {
                return this.filteredSortedLeads.slice(
                    (this.tableState.currentPage - 1) * this.tableState.perPage,
                    this.tableState.currentPage * this.tableState.perPage,
                );
            },
        },

        watch: {
            '$route.query.type': {
                immediate: true,
                handler(newValue) {
                    if (newValue == 'recent') {
                        this.updateTableSorting({
                            prop: 'mainDate',
                            order: 'descending',
                        });
                    } else if (newValue == 'lead') {
                        this.updateTableSorting({
                            prop: 'fullName',
                            order: 'asc',
                        });
                    }
                },
            },
        },

        methods: {
            displayPhone(phoneNumber) {
                const number = this.formatPhone(phoneNumber, true);

                if (this.authUser.block_lead_info) {
                    return this.maskPhone(number);
                }

                return number;
            },

            displayEmail(email) {
                if (this.authUser.block_lead_info) {
                    return this.maskEmail(email);
                }

                return email;
            },

            onDelete(id) {
                this.deleteModal.opened = true;
                this.deleteModal.leadId = id;
            },

            async deleteLead() {
                const id = this.deleteModal.leadId;

                if (!id) {
                    return;
                }

                try {
                    await this.$axios.delete(`v1/leads/${id}`);

                    this.$notify.success(this.$t('client.leadDeletedSuccessfully'));
                    this.$emit('reload');
                } catch (error) {
                    this.$notify.error(this.$t('client.deleteLeads.failedSingle'));

                    throw error;
                }
            },
        },
    };
</script>
