import { orderBy } from 'lodash-es';
import { latinize } from '../utils/string.js';
import Pagination from './Pagination.js';
import ActivixDate from '../value-objects/ActivixDate.js';

export default {
    mixins: [Pagination],

    data() {
        return {
            tableState: {
                searchToken: '',
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                sorting: {
                    column: 'created_at',
                    order: 'desc',
                },
            },
        };
    },

    methods: {
        searchData(token, data) {
            if (!token) {
                return data;
            }

            const normalizedToken = latinize(token.toLowerCase().trim());

            return data.filter(row => {
                return Object.entries(row.raw || row).some(object => {
                    const [key, value] = object;
                    let normalizedValue;

                    if (key.includes('_at')) {
                        normalizedValue = new ActivixDate(value).toHumanShort();
                    } else if (key.includes('_by') && typeof value === 'object') {
                        normalizedValue = latinize(String(`${value?.first_name} ${value?.last_name}`).toLowerCase().trim());
                    } else {
                        normalizedValue = latinize(String(value).toLowerCase().trim());
                    }

                    return normalizedValue.includes(normalizedToken);
                });
            });
        },

        orderData() {
            const searchedData = this.searchData(this.tableState.searchToken, this.tableData);
            const sortedData = orderBy(
                searchedData,
                [this.tableState.sorting.column],
                [this.tableState.sorting.order],
            );

            return sortedData;
        },

        updateTablePerPage(newValue) {
            if (!newValue && newValue === this.tableState.perPage) {
                return;
            }

            this.tableState.perPage = newValue;
        },

        updateTableSorting(state) {
            this.tableState.sorting.column = state.prop;
            this.tableState.sorting.order = state.order === 'descending' ? 'desc' : 'asc';
        },

        handleTableDateFormat(row, column, cellValue) {
            return locale_date(cellValue).humanShort(true) || '-';
        },

        handleTableDateTimeFormat(row, column, cellValue) {
            return locale_dt(cellValue).humanShort() || '-';
        },
    },
};
