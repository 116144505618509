<template>
    <div class="overflow-hidden">
        <div class="flex-1 flex items-center" @mouseenter="fetchLead(lead.id)">
            <activix-tooltip :content="statusTooltip">
                <div class="truncate">
                    <router-link
                        :class="{
                            'lost line-through': lead.status == 'lost',
                            'invalid line-through': lead.status == 'invalid',
                            'duplicate line-through': lead.status == 'duplicate',
                        }"
                        :to="{ name: 'leads.update', params: { id: lead.id } }"
                        @click="clearAllSeenEmailReadAlert(lead.communications)"
                        v-html="fullName"
                    />
                </div>
            </activix-tooltip>

            <activix-tooltip :content="associationTooltip" v-if="hasAssociatedLeads">
                <span class="text-grey-500 ml-1 whitespace-nowrap">({{ relatedLeads.length }})</span>
            </activix-tooltip>

            <activix-tooltip :content="pendingReassignTooltip">
                <span class="ml-2">
                    <icon
                        class="text-orange-500"
                        name="regular/human-resources-employee-previous"
                        v-if="pendingReassign"
                    />
                </span>
            </activix-tooltip>
        </div>

        <template v-if="!light">
            <!-- Revive WebBoost -->
            <div class="revive-webboost | absolute" v-if="reviveWebBoost">
                <activix-tooltip :content="$t('webBoost.reviveWebBoost')">
                    <icon
                        name="regular/phone-actions-next"
                        class="link-grey text-sm"
                        @click="$modal.show('dashboard:reviveWebBoost', lead.id)"
                    />
                </activix-tooltip>
            </div>

            <!-- Edit task events -->
            <div class="edit-reminders | absolute flex items-center" v-if="hasBellOption">
                <div
                    class="cursor-pointer flex items-center mr-2"
                    :class="{ 'opacity-50': taskEvent.status }"
                    :key="taskEvent.id"
                    @click="openEditTaskEvent(taskEvent)"
                    v-for="taskEvent in sortedTaskEvents"
                >
                    <activix-tooltip :content="taskEvent.tooltip">
                        <span class="text-xs" :class="{ 'text-red-500': taskEvent.isLate }" v-html="taskEvent.icon" />
                    </activix-tooltip>
                </div>
            </div>

            <!-- Add task event -->
            <div class="add-reminder | absolute">
                <activix-tooltip :content="$t('modal.add_event')">
                    <icon :name="$icons.add" class="link-grey text-2xs" @click="openAddTaskEvent" />
                </activix-tooltip>
            </div>
        </template>
    </div>
</template>

<script>
    /* eslint-disable vue/require-prop-types */
    import { mapActions, mapState } from 'pinia';
    import { sortBy } from 'lodash-es';

    import TaskEventType from '../../../entities/TaskEventType.js';
    import TaskEventTypeIcon from '../../../entities/TaskEventTypeIcon.js';
    import ActivixDate from '../../../value-objects/ActivixDate.js';
    import { useDashboardStore } from '../../../store/modules/dashboard/store.js';
    import { useGlobalStore } from '../../../store/store.js';

    export default {
        props: ['lead', 'light'],

        computed: {
            ...mapState(useGlobalStore, ['authUser', 'leads']),
            ...mapState(useDashboardStore, ['dashboardType']),

            pendingReassign() {
                return this.authUser.id == this.lead.second_bdc_user_id || this.authUser.id == this.lead.second_user_id;
            },

            pendingReassignTooltip() {
                return this.pendingReassign ? this.$t('client.pendingReassign') : '';
            },

            hasBellOption() {
                return this.authUser.bell_event_option;
            },

            tasksLimit() {
                if (this.relatedLeads.length === 0) {
                    return 7;
                }

                if (this.relatedLeads.length < 100) {
                    return 6;
                }

                return 5;
            },

            associationTooltip() {
                return this.$tc('client.associatedLeads', this.relatedLeads.length, [this.relatedLeads.length]);
            },

            hasAssociatedLeads() {
                return !!this.relatedLeads.length;
            },

            statusTooltip() {
                let tooltip = '';

                if (['invalid', 'duplicate', 'lost'].includes(this.lead.status)) {
                    tooltip = this.$t(`result.${this.lead.status}`);
                }

                return tooltip;
            },

            relatedLeads() {
                if (!this.lead?.customer?.leads?.length) {
                    return this.lead.associated_leads || [];
                }

                return this.lead.customer.leads.filter(lead => {
                    return (
                        lead.id != this.lead.id &&
                        (!lead.import || (!!lead.import.finished_at && lead.import.validated))
                    );
                });
            },

            fullName() {
                let name = this.lead.fullName || this.$t('general.unknown');

                if (this.lead.business && this.lead.business_name) {
                    name += '<br/>';
                    name += `${this.lead.first_name} ${this.lead.last_name}`;
                }

                if (this.lead.second_contact) {
                    name += '<br>';
                    name += this.lead.second_contact;
                }
                if (!this.lead.business && this.lead.business_name) {
                    name += '<br>';
                    name += this.lead.business_name.trim();
                }

                return name;
            },

            reviveWebBoost() {
                const firstCommunication = this.lead.communications[0];

                return (
                    this.dashboardType == 'webBoost' &&
                    this.lead.account.webboost &&
                    this.lead.user_id == null &&
                    this.lead.bdc_user_id == null &&
                    this.authUser.role_id <= 4 &&
                    this.lead.result != 'reached' &&
                    !this.lead.appointment_date &&
                    !this.lead.be_back_date &&
                    !this.lead.road_test_date &&
                    !this.lead.take_over_date &&
                    !this.lead.presented_date &&
                    !this.lead.sale_date &&
                    !this.lead.delivered_date &&
                    !empty(firstCommunication) &&
                    firstCommunication.status != 'error' &&
                    (firstCommunication.duration_reached == null || firstCommunication.duration_reached < 60)
                );
            },

            sortedTaskEvents() {
                const taskEvents = sortBy(this.lead.task_events, ['start_at']).slice(0, this.tasksLimit);

                return taskEvents.map(taskEvent => {
                    const startAt = new ActivixDate(taskEvent.start_at);
                    const endAt = new ActivixDate(taskEvent.end_at);

                    return {
                        ...taskEvent,
                        tooltip: this.getTaskEventTooltip(taskEvent, startAt, endAt),
                        icon: this.getTaskEventIcon(taskEvent),
                        isLate: startAt.isPast() && !taskEvent.status,
                    };
                });
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['fetchLead']),

            getTaskEventTooltip(taskEvent, start, end) {
                let tooltip = '';

                if (!empty(taskEvent.title)) {
                    tooltip += `<strong>${taskEvent.title}</strong>`;
                }

                if (tooltip && !start.isEmpty()) {
                    if ([3, 4, 5].includes(taskEvent.task_event_type_id)) {
                        const startTime = start.toHumanTime();
                        const endTime = end.toHumanTime();

                        tooltip += `
                            <br>${start.toHumanShort(false)}
                            <br>${startTime} - ${endTime}
                        `;
                    } else {
                        tooltip += `<br>${start.toHumanShort()}`;
                    }
                }

                return tooltip;
            },

            getTaskEventIcon(taskEvent) {
                const taskEventType = TaskEventType.getKey(taskEvent.task_event_type_id);

                return TaskEventTypeIcon.entries[taskEventType];
            },

            openAddTaskEvent() {
                this.$eventBus.$emit('open-add-task-event', {
                    leadId: this.lead.id,
                });
            },

            openEditTaskEvent(taskEvent) {
                this.$eventBus.$emit('open-edit-task-event', {
                    lead: this.lead,
                    taskEvent,
                });
            },
        },
    };
</script>

<style lang="less" scoped>
    .revive-webboost {
        top: 4px;
        left: 8px;
    }

    .add-reminder {
        bottom: 6px;
        right: 8px;
    }

    .edit-reminders {
        bottom: 6px;
        left: 8px;
    }
</style>
