<template>
    <div class="box" :class="{ loading: loading }">
        <div class="box-header | h-auto p-6 flex justify-between items-center">
            <h4 class="box-title | flex items-baseline">
                {{ $t('search.results') }}
                <activix-reload :loading="loading" @click="$emit('reload')" />
            </h4>
            <div>
                <input
                    class="form-control w-auto"
                    type="text"
                    :placeholder="$t('search.search')"
                    v-model="tableState.searchToken"
                />
            </div>
        </div>
        <div class="box-body | p-0">
            <el-table
                class="w-full border-t-2 border-b"
                :border="true"
                :data="paginatedVehicles"
                :default-sort="{ prop: 'fullName', order: 'ascending' }"
                :stripe="true"
                @sort-change="updateTableSorting"
            >
                <el-table-column
                    prop="fullName"
                    header-align="center"
                    :label="$t('clientCard.name')"
                    :sortable="true"
                >
                    <template slot-scope="{ row: vehicle }">
                        <router-link :to="{ name: 'leads.update', params: { id: vehicle.lead.id } }">
                            <client-name :lead="vehicle.lead" :light="true" />
                        </router-link>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="status"
                    header-align="center"
                    align="center"
                    :label="$t('clientCard.status')"
                    :sortable="true"
                    min-width="100"
                >
                    <template slot-scope="{ row: vehicle }">
                        <lead-status :lead="vehicle.lead" :disabled="true" />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="leadPhones"
                    align="center"
                    width="105"
                    :label="$t('clientCard.phone')"
                    :sortable="true"
                >
                    <template slot-scope="{ row: vehicle }">
                        <template v-if="!vehicle.lead.phones.length">
                            -
                        </template>
                        <div :key="phone.id" v-for="phone in vehicle.lead.phones">
                            {{ formatPhone(phone.number, true) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="leadEmails"
                    :label="$t('clientCard.email')"
                    :sortable="true"
                >
                    <template slot-scope="{ row: vehicle }">
                        <template v-if="!vehicle.lead.emails.length">
                            -
                        </template>
                        <div :key="email.id" v-for="email in vehicle.lead.emails">
                            {{ email.address }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="advisorName"
                    :label="$t('general.advisor')"
                    :sortable="true"
                />
                <el-table-column
                    align="center"
                    prop="mainDate"
                    :label="$t('clientCard.date')"
                    :sortable="true"
                    :formatter="handleTableDateTimeFormat"
                />
                <el-table-column
                    align="center"
                    prop="lead.updated_at"
                    :label="$t('clientCard.updatedAt')"
                    :sortable="true"
                    :formatter="handleTableDateTimeFormat"
                />
                <el-table-column
                    align="center"
                    prop="wantedVehicle"
                    :label="$t('clientCard.vehicle')"
                    :sortable="true"
                />
                <el-table-column
                    align="center"
                    prop="vehicleType"
                    :label="$t('clientCard.type')"
                    :sortable="true"
                />
                <el-table-column
                    align="center"
                    prop="wantedStock"
                    width="90"
                    :label="$t('general.stock')"
                    :sortable="true"
                />
                <el-table-column
                    align="center"
                    prop="wantedOffer"
                    width="80"
                    :label="$t('general.offer')"
                    :sortable="true"
                />
                <el-table-column
                    align="center"
                    prop="wantedOrder"
                    :label="$t('accounts.edit.orderNumber')"
                    :sortable="true"
                />
                <el-table-column
                    align="center"
                    prop="wantedVin"
                    :label="`#${$t('accounts.edit.vin')}`"
                    :sortable="true"
                />
                <el-table-column
                    align="center"
                    prop="typeName"
                    width="120"
                    :label="$t('searchLead.lead_type')"
                    :sortable="true"
                />
                <el-table-column
                    align="center"
                    prop="accountName"
                    :label="$t('general.account')"
                    :sortable="true"
                />
                <el-table-column
                    align="center"
                    prop="sale"
                    width="70"
                    :label="$t('clientCard.sold')"
                    :sortable="true"
                >
                    <template slot-scope="{ row: vehicle }">
                        {{ vehicle.lead.sale_date ? $t('general.yes') : $t('general.no') }}
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="delivered"
                    width="80"
                    :label="$t('date.delivered')"
                    :sortable="true"
                >
                    <template slot-scope="{ row: vehicle }">
                        {{ vehicle.lead.delivered_date ? $t('general.yes') : $t('general.no') }}
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :class="mdLayout ? 'p-4' : 'p-6'"
                :layout="paginationLayout"
                :background="true"
                :current-page.sync="tableState.currentPage"
                :page-size.sync="tableState.perPage"
                :page-sizes="tableState.pageOptions"
                :pager-count="5"
                :total="filteredSortedVehicles.length"
                @size-change="updateTablePerPage"
            />
        </div>
    </div>
</template>

<script>
    import { orderBy, camelCase } from 'lodash-es';

    import { mapState } from 'pinia';
    import { formater } from '../../globals/LeadFormatter.js';
    import DataTableMixin from '../../mixins/DataTable.js';
    import LeadType from '../../entities/LeadType.js';
    import Lead from '../../entities/Lead.js';
    import LeadVehicle from '../../entities/LeadVehicle.js';

    import ClientName from '../dashboards/columns/ClientName.vue';
    import LeadStatus from '../dashboards/columns/LeadStatus.vue';
    import { useContextStore } from '../../store/modules/context/store.js';

    export default {
        components: {
            ClientName,
            LeadStatus,
        },

        mixins: [DataTableMixin],

        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            results: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                tableState: {
                    searchToken: '',
                    currentPage: 1,
                    perPage: 10,
                    sorting: {
                        column: 'created_at',
                        order: 'desc',
                    },
                },
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            formatedVehicles() {
                return this.results.map(tmpVehicle => {
                    const lead = new Lead(tmpVehicle.lead);
                    const vehicle = new LeadVehicle(tmpVehicle);

                    vehicle.fullName = lead.fullName;
                    vehicle.status = lead.status;
                    vehicle.sale = lead.sale_date;
                    vehicle.delivered = lead.delivered_date;
                    vehicle.advisorName = this.getFullName(lead.user, false) || '-';
                    vehicle.wantedVehicle = vehicle.toString() || '-';
                    vehicle.vehicleType = this.$t(`searchLead.${vehicle.type}`);
                    vehicle.wantedStock = vehicle.stock || '-';
                    vehicle.wantedVin = vehicle.vin || '-';
                    vehicle.wantedOffer = vehicle.offer_number || '-';
                    vehicle.wantedOrder = vehicle.order_number || '-';
                    vehicle.leadPhones = formater.formatPhone(lead) || '-';
                    vehicle.leadEmails = formater.formatEmail(lead) || '-';
                    vehicle.accountName = formater.formatAccountName(lead);
                    vehicle.typeName = this.$t(`leadTypes.${camelCase(lead.type)}`);
                    vehicle.lead = lead;

                    switch (lead.lead_type_id) {
                        case LeadType.PHONE:
                            vehicle.mainDate = lead.call_date;
                            break;

                        case LeadType.WALK_IN:
                            vehicle.mainDate = lead.presented_date;
                            break;

                        case LeadType.EMAIL:
                        case LeadType.LOYALTY:
                        case LeadType.RENEWAL:
                        case LeadType.WEB_ORDER:
                        default:
                            vehicle.mainDate = lead.created_at;
                            break;
                    }

                    return vehicle;
                });
            },

            filteredSortedVehicles() {
                const filteredData = this.searchData(this.tableState.searchToken, this.formatedVehicles);
                const sortedData = orderBy(
                    filteredData,
                    [this.tableState.sorting.column],
                    [this.tableState.sorting.order],
                );

                return sortedData;
            },

            paginatedVehicles() {
                const start = (this.tableState.currentPage - 1) * this.tableState.perPage;
                const end = this.tableState.currentPage * this.tableState.perPage;
                return this.filteredSortedVehicles.slice(start, end);
            },
        },
    };
</script>
