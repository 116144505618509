<template>
    <div class="box" :class="{ loading: loading }">
        <div class="box-header | h-auto p-6 flex justify-between items-center">
            <h4 class="box-title | flex items-baseline">
                {{ $t('search.results') }}
                <activix-reload :loading="loading" @click="$emit('reload')" />
            </h4>
            <div>
                <input
                    class="form-control w-auto"
                    type="text"
                    :placeholder="$t('search.search')"
                    v-model="tableState.searchToken"
                />
            </div>
        </div>
        <div class="box-body | p-0">
            <el-table
                class="w-full border-t-2 border-b"
                :border="true"
                :data="paginatedUsers"
                :default-sort="{ prop: 'fullName', order: 'ascending' }"
                :stripe="true"
                @sort-change="updateTableSorting"
            >
                <el-table-column
                    prop="fullName"
                    header-align="center"
                    :label="$t('clientCard.name')"
                    :sortable="true"
                >
                    <template slot-scope="{ row: user }">
                        <router-link :to="{ name: 'users.update', params: { id: user.id } }">
                            {{ user.fullName }}
                        </router-link>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    prop="primaryPhone"
                    :label="$t('clientCard.phone')"
                    :sortable="true"
                />
                <el-table-column
                    align="center"
                    prop="email"
                    min-width="150"
                    :label="$t('clientCard.email')"
                    :sortable="true"
                />
                <el-table-column
                    align="center"
                    prop="accountName"
                    :label="$t('general.account')"
                    :sortable="true"
                />
                <el-table-column
                    align="center"
                    prop="role"
                    :label="$t('users.edit.role')"
                    :sortable="true"
                />
                <el-table-column
                    align="center"
                    prop="divisions"
                    :label="$t('general.division')"
                    :sortable="true"
                />
                <el-table-column
                    align="center"
                    prop="locale"
                    :label="$t('clientCard.language')"
                    :sortable="true"
                />
                <el-table-column
                    align="center"
                    prop="active"
                    :label="$t('general.active')"
                    :sortable="true"
                />
                <el-table-column
                    align="center"
                    width="50"
                    :sortable="false"
                    v-if="authUser.isAdmin()"
                >
                    <template slot-scope="{ row: user }">
                        <activix-tooltip :content="$t('users.global.impersonate')" v-if="canImpersonate(user)">
                            <icon name="regular/login-1" class="text-lg link-grey" @click="impersonate(user.id)" />
                        </activix-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :class="mdLayout ? 'p-4' : 'p-6'"
                :layout="paginationLayout"
                :background="true"
                :current-page.sync="tableState.currentPage"
                :page-size.sync="tableState.perPage"
                :page-sizes="tableState.pageOptions"
                :pager-count="5"
                :total="filteredSortedUsers.length"
                @size-change="updateTablePerPage"
            />
        </div>
    </div>
</template>

<script>
    import { orderBy } from 'lodash-es';
    import { mapState } from 'pinia';
    import { formater } from '../../globals/LeadFormatter.js';

    import DataTableMixin from '../../mixins/DataTable.js';

    import Account from '../../entities/Account.js';
    import User from '../../entities/User.js';

    // Pinia
    import { useContextStore } from '../../store/modules/context/store.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        mixins: [DataTableMixin],
        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            results: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                tableState: {
                    searchToken: '',
                    currentPage: 1,
                    perPage: 10,
                    sorting: {
                        column: 'created_at',
                        order: 'desc',
                    },
                },
            };
        },

        computed: {
            ...mapState(useGlobalStore, ['authUser']),
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            formatedUsers() {
                return this.results.map(tmpUser => {
                    const user = new User(tmpUser);
                    const phone = user.phones[0];

                    user.primaryPhone = phone ? this.formatPhone(phone.number, true) : '-';
                    user.accountName = user.account.name;
                    user.divisions = formater.formatUserDivisions(user);
                    user.locale = user.locale ? this.$t(`database.${user.locale}`) : '-';
                    user.active = user.active ? this.$t('general.yes') : this.$t('general.no');

                    return user;
                });
            },

            filteredSortedUsers() {
                const filteredData = this.searchData(this.tableState.searchToken, this.formatedUsers);
                const sortedData = orderBy(
                    filteredData,
                    [this.tableState.sorting.column],
                    [this.tableState.sorting.order],
                );

                return sortedData;
            },

            paginatedUsers() {
                return this.filteredSortedUsers.slice(
                    (this.tableState.currentPage - 1) * this.tableState.perPage,
                    this.tableState.currentPage * this.tableState.perPage,
                );
            },
        },

        methods: {
            canImpersonate(user) {
                return this.authUser.isAdmin() && user.account_id != Account.ACTIVIX;
            },

            async impersonate(userId) {
                this.$ls.remove('context');

                await this.$nextTick();

                this.$auth.impersonate({
                    data: {
                        userId,
                    },
                });
            },
        },
    };
</script>
