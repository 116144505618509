<template>
    <div class="flex flex-col">
        <portal to="content-header-left">
            <content-header-sub>{{ value }}</content-header-sub>
        </portal>

        <warning
            :icon="$icons.warning"
            :title="$t('error.warning')"
            :content="$t('error.selectAccountWarning')"
            v-if="!validAccount && !$wait.is('fetching.search')"
        />

        <component
            :is="component"
            :loading="$wait.is('fetching.search')"
            :results="results"
            @reload="fetch"
            v-else
        />
    </div>
</template>

<script>
    import { mapState } from 'pinia';
    import ContentHeaderSub from '../components/ContentHeaderSub.vue';
    import SearchLead from '../components/search/SearchLead.vue';
    import SearchUser from '../components/search/SearchUser.vue';
    import SearchVehicle from '../components/search/SearchVehicle.vue';
    import Warning from '../components/Warning.vue';
    import TrackView from '../mixins/TrackView.js';
    import { useContextStore } from '../store/modules/context/store.js';

    export default {
        name: 'Search',

        components: {
            ContentHeaderSub,
            SearchLead,
            SearchUser,
            SearchVehicle,
            Warning,
        },

        mixins: [TrackView],

        data() {
            return {
                type: '',
                value: '',
                validTypes: ['lead', 'user', 'vehicle', 'recent'],
                leads: [],
                users: [],
                vehicles: [],
            };
        },

        computed: {
            ...mapState(useContextStore, {
                contextAccount: 'account',
            }),

            validAccount() {
                return !!this.contextAccount.id;
            },

            component() {
                switch (this.type) {
                    case 'lead':
                    case 'recent':
                        return 'search-lead';
                    case 'user':
                        return 'search-user';
                    case 'vehicle':
                        return 'search-vehicle';
                }

                return '';
            },
        },

        watch: {
            '$route.query': {
                immediate: true,
                handler(newValue, oldValue) {
                    if (newValue.type && newValue.type != oldValue?.type) {
                        this.setType(newValue.type);
                    }

                    if (newValue.value && newValue.value != oldValue?.value) {
                        this.setValue(newValue.value);
                    }

                    this.fetch();
                },
            },

            'contextAccount.id'() {
                this.fetch();
            },
        },

        methods: {
            setType(newType) {
                if (!this.validTypes.includes(newType)) {
                    newType = 'lead';
                }

                if (newType == 'recent') {
                    this.value = '';
                }

                if (newType != this.type) {
                    this.results = [];
                    this.type = newType;
                }
            },

            setValue(newValue) {
                if (!newValue) {
                    newValue = '';
                }

                if (newValue != this.value) {
                    this.value = newValue;
                }
            },

            async fetch() {
                if (
                    !this.validTypes.includes(this.type) ||
                    (this.type != 'recent' && !this.value) ||
                    this.$wait.is('fetching.search')
                ) {
                    return;
                }

                this.$wait.start('fetching.search');

                try {
                    const response = await this.$axios.get('v1/search', {
                        params: {
                            valueSearch: this.value,
                            selectSearch: this.type,
                            accountId: this.contextAccount.id,
                        },
                    });

                    this.results = response.data.data;
                    this.$wait.end('fetching.search');
                } catch (error) {
                    this.$notify.warning(this.$t('search.alerts.index.error'));
                    this.$wait.end('fetching.search');

                    throw error;
                }
            },
        },
    };
</script>
