<template>
    <activix-modal
        :opened="opened"
        size="xs"
        @close="close"
        @closed="onClosed"
    >
        <template slot="header">
            <h4 class="modal-title">
                {{ $t(`result.${status}Reason`) }}
            </h4>
        </template>

        <template slot="body">
            <textarea
                name="lost_reason"
                class="form-control | mb-4"
                rows="5"
                v-model="lostReason"
            />
            <activix-multiselect
                label="value"
                :options="lostSourceOptions"
                :searchable="false"
                :selected="selectedSource"
                :allow-empty="false"
                @update="updateLostReason"
                v-if="status == 'lost'"
            />
        </template>

        <template slot="footer">
            <activix-button @click="close">
                {{ $t('general.cancel') }}
            </activix-button>
            <activix-button type="primary" :disabled="empty(lostReason)" @click="submit">
                {{ $t('general.save') }}
            </activix-button>
        </template>
    </activix-modal>
</template>

<script>
    import { mapActions } from 'pinia';
    import ActivixDate from '../../value-objects/ActivixDate.js';
    import { useGlobalStore } from '../../store/store.js';

    export default {
        props: {
            opened: {
                type: Boolean,
                required: true,
            },
            status: {
                type: String,
                default: 'lost',
            },
            lead: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                approved: false,
                lostReason: '',
                lostSource: 'client',
                lostSourceOptions: [
                    {
                        id: 'client',
                        value: this.$t('result.canceledByClient'),
                    },
                    {
                        id: 'dealer',
                        value: this.$t('result.canceledByDealer'),
                    },
                ],
            };
        },

        computed: {
            selectedSource() {
                return this.lostSourceOptions.find(source => source.id == this.lostSource);
            },
        },

        methods: {
            ...mapActions(useGlobalStore, ['updateLeadAction']),

            updateLostReason({ id }) {
                this.lostSource = id;
            },

            submit() {
                this.updateLeadAction(
                    this.lead.id,
                    {
                        status: this.status,
                        lost_reason: this.lostReason,
                        lost_source: this.lostSource,
                        status_updated_at: new ActivixDate('now').toString(),
                    },
                    { deleteUpcomingTask: this.deleteUpcomingTask },
                );

                this.approved = true;
                this.close();
            },

            close() {
                this.$emit('update:opened', false);
            },

            onClosed() {
                this.lostReason = '';
                this.lostSource = 'client';

                if (this.approved) {
                    this.$emit('approved');
                }

                this.approved = false;
            },
        },
    };
</script>
