var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box",class:{ loading: _vm.loading }},[_c('div',{staticClass:"box-header | h-auto p-6 flex justify-between items-center"},[_c('h4',{staticClass:"box-title | flex items-baseline"},[_vm._v(" "+_vm._s(_vm.$t('search.results'))+" "),_c('activix-reload',{attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.$emit('reload')}}})],1),_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tableState.searchToken),expression:"tableState.searchToken"}],staticClass:"form-control w-auto",attrs:{"type":"text","placeholder":_vm.$t('search.search')},domProps:{"value":(_vm.tableState.searchToken)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.tableState, "searchToken", $event.target.value)}}})])]),_c('div',{staticClass:"box-body | p-0"},[_c('el-table',{staticClass:"w-full border-t-2 border-b",attrs:{"border":true,"data":_vm.paginatedVehicles,"default-sort":{ prop: 'fullName', order: 'ascending' },"stripe":true},on:{"sort-change":_vm.updateTableSorting}},[_c('el-table-column',{attrs:{"prop":"fullName","header-align":"center","label":_vm.$t('clientCard.name'),"sortable":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var vehicle = ref.row;
return [_c('router-link',{attrs:{"to":{ name: 'leads.update', params: { id: vehicle.lead.id } }}},[_c('client-name',{attrs:{"lead":vehicle.lead,"light":true}})],1)]}}])}),_c('el-table-column',{attrs:{"prop":"status","header-align":"center","align":"center","label":_vm.$t('clientCard.status'),"sortable":true,"min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var vehicle = ref.row;
return [_c('lead-status',{attrs:{"lead":vehicle.lead,"disabled":true}})]}}])}),_c('el-table-column',{attrs:{"prop":"leadPhones","align":"center","width":"105","label":_vm.$t('clientCard.phone'),"sortable":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var vehicle = ref.row;
return [(!vehicle.lead.phones.length)?[_vm._v(" - ")]:_vm._e(),_vm._l((vehicle.lead.phones),function(phone){return _c('div',{key:phone.id},[_vm._v(" "+_vm._s(_vm.formatPhone(phone.number, true))+" ")])})]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"leadEmails","label":_vm.$t('clientCard.email'),"sortable":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var vehicle = ref.row;
return [(!vehicle.lead.emails.length)?[_vm._v(" - ")]:_vm._e(),_vm._l((vehicle.lead.emails),function(email){return _c('div',{key:email.id},[_vm._v(" "+_vm._s(email.address)+" ")])})]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"advisorName","label":_vm.$t('general.advisor'),"sortable":true}}),_c('el-table-column',{attrs:{"align":"center","prop":"mainDate","label":_vm.$t('clientCard.date'),"sortable":true,"formatter":_vm.handleTableDateTimeFormat}}),_c('el-table-column',{attrs:{"align":"center","prop":"lead.updated_at","label":_vm.$t('clientCard.updatedAt'),"sortable":true,"formatter":_vm.handleTableDateTimeFormat}}),_c('el-table-column',{attrs:{"align":"center","prop":"wantedVehicle","label":_vm.$t('clientCard.vehicle'),"sortable":true}}),_c('el-table-column',{attrs:{"align":"center","prop":"vehicleType","label":_vm.$t('clientCard.type'),"sortable":true}}),_c('el-table-column',{attrs:{"align":"center","prop":"wantedStock","width":"90","label":_vm.$t('general.stock'),"sortable":true}}),_c('el-table-column',{attrs:{"align":"center","prop":"wantedOffer","width":"80","label":_vm.$t('general.offer'),"sortable":true}}),_c('el-table-column',{attrs:{"align":"center","prop":"wantedOrder","label":_vm.$t('accounts.edit.orderNumber'),"sortable":true}}),_c('el-table-column',{attrs:{"align":"center","prop":"wantedVin","label":("#" + (_vm.$t('accounts.edit.vin'))),"sortable":true}}),_c('el-table-column',{attrs:{"align":"center","prop":"typeName","width":"120","label":_vm.$t('searchLead.lead_type'),"sortable":true}}),_c('el-table-column',{attrs:{"align":"center","prop":"accountName","label":_vm.$t('general.account'),"sortable":true}}),_c('el-table-column',{attrs:{"align":"center","prop":"sale","width":"70","label":_vm.$t('clientCard.sold'),"sortable":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var vehicle = ref.row;
return [_vm._v(" "+_vm._s(vehicle.lead.sale_date ? _vm.$t('general.yes') : _vm.$t('general.no'))+" ")]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"delivered","width":"80","label":_vm.$t('date.delivered'),"sortable":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var vehicle = ref.row;
return [_vm._v(" "+_vm._s(vehicle.lead.delivered_date ? _vm.$t('general.yes') : _vm.$t('general.no'))+" ")]}}])})],1),_c('el-pagination',{class:_vm.mdLayout ? 'p-4' : 'p-6',attrs:{"layout":_vm.paginationLayout,"background":true,"current-page":_vm.tableState.currentPage,"page-size":_vm.tableState.perPage,"page-sizes":_vm.tableState.pageOptions,"pager-count":5,"total":_vm.filteredSortedVehicles.length},on:{"update:currentPage":function($event){return _vm.$set(_vm.tableState, "currentPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.tableState, "currentPage", $event)},"update:pageSize":function($event){return _vm.$set(_vm.tableState, "perPage", $event)},"update:page-size":function($event){return _vm.$set(_vm.tableState, "perPage", $event)},"size-change":_vm.updateTablePerPage}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }